import { React, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  HashRouter,
  Link,
} from 'react-router-dom';
import Details from './Screens/DetailsScreen/Details';
import Insights from './Screens/DetailsInsights/Insights';
import Footer from './Screens/Footer/Footer';
import Header from './Screens/Headers/Header';
import Home from './Screens/Home/Home';
import Join from './Screens/DetailsJoin/Join';
import About from './Screens/DetailsAbout/About';
import Contact from './Screens/DetailsContact/Contact';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/information/contact-us"
          element={
            <>
              <Header />
              <Contact />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/information/about-us"
          element={
            <>
              <Header />
              <About />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/information/join-us"
          element={
            <>
              <Header />
              <Join />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/information/insights"
          element={
            <>
              <Header />
              <Insights />
              <Footer />
            </>
          }
        ></Route>

        <Route
          path="/information/service"
          element={
            <>
              <Header />
              <Details />
              <Footer />
            </>
          }
        ></Route>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
