import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import './CounterUpPage.css';
function CounterUpPage() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="counter">
        <h1>Why work with us</h1>
        <p>Some interesting facts & numbers.</p>
        <div className="items">
          <div className="count_item">
            <h1>
              {counterOn && (
                <CountUp start={0} end={98} duration={2} delay={0} />
              )}
              %
            </h1>
            <span>CUSTOMER SATISFACTION</span>
          </div>

          <div className="count_item">
            <h1>
              {counterOn && (
                <CountUp start={0} end={65} duration={2} delay={0} />
              )}
              +
            </h1>
            <span>SPECIALISTS IN OUR TEAM</span>
          </div>

          <div className="count_item">
            <h1>
              {counterOn && (
                <CountUp start={0} end={30} duration={2} delay={0} />
              )}
            </h1>
            <span>INDUSTRY AWARDS</span>
          </div>

          <div className="count_item">
            <h1>
              {counterOn && (
                <CountUp start={0} end={300} duration={2} delay={0} />
              )}
              +
            </h1>
            <span>SUCCESSFUL PROJECTS</span>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
}

export default CounterUpPage;
