import React, { useEffect, useReducer } from 'react';
import CounterUpPage from '../CounterUpPage/CounterUpPage';
import './DetailsScreen.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Details() {
  return (
    <div className="services">
      <Helmet>
        <title>Service - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs_service p_s">
                    <div className="all_seme_serv">
                      <div className="title_serv">
                        <h1>Service</h1>
                      </div>
                      <div className="small_description_serv">
                        Wise offers a full range of professional services
                        including audit, accounting, tax, advisory and legal
                        services for a wide range of clients across the spectrum
                        of industry sectors. Through the integrated team that we
                        have, we have been able to create a base of
                        <br />
                        professionals who can help develop your organization and
                        help it grow along with adhering to the general
                        principles and standards.
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seconed section */}
      <section>
        <div className="service scontainer">
          <div className="container_service">
            <div className="title_service">
              <h2>Service</h2>
            </div>
            <section className="professional_field scontainer">
              <a href="#" class="clickable-card">
                <article>
                  <div class="article-wrapper">
                    <figure>
                      <img src="/serv1.jpg" alt="" />
                    </figure>
                    <div class="article-body">
                      <h2>Tax & Legal</h2>
                      <p>
                        You face steep compliance demands and complex changes;
                        we help you transform your function to make a greater
                        impact.
                      </p>
                      <div class="read-more">Read more</div>
                    </div>
                  </div>
                </article>
              </a>
              <a href="#" class="clickable-card">
                <article>
                  <div class="article-wrapper">
                    <figure>
                      <img src="/serv3.jpg" alt="" />
                    </figure>
                    <div class="article-body">
                      <h2>Audit</h2>
                      <p>
                        We are committed to serving the public interest by
                        providing objective assurance over data on which
                        investors rely.
                      </p>
                      <div class="read-more">Read more</div>
                    </div>
                  </div>
                </article>
              </a>
              <a href="#" class="clickable-card">
                <article>
                  <div class="article-wrapper">
                    <figure>
                      <img src="/serv2.jpg" alt="" />
                    </figure>
                    <div class="article-body">
                      <h2>Advisory</h2>
                      <p>
                        Together, we help you create lasting value and
                        responsible growth to make your business fit for
                        tomorrow.
                      </p>
                      <div class="read-more">Read more</div>
                    </div>
                  </div>
                </article>
              </a>
              <a href="#" class="clickable-card">
                <article>
                  <div class="article-wrapper">
                    <figure>
                      <img src="/serv4.jpg" alt="" />
                    </figure>
                    <div class="article-body">
                      <h2>Private Enterprise</h2>
                      <p>
                        Helping entrepreneurs build great businesses. We know
                        what it takes to be successful at each stage of your
                        business.
                      </p>
                      <div class="read-more">Read more</div>
                    </div>
                  </div>
                </article>
              </a>
            </section>
          </div>
        </div>
      </section>
      <section>
        <div className="art scontainer">
          <div className="art_1">
            <h2>Tax return preparation services</h2>
            <ul>
              <li>
                Preparing and reviewing the analyzes required to prepare the tax
                return
              </li>
              <li>
                Preparing the tax return in light of legal requirements and tax
                legislation.
              </li>
              <li>Approval of the tax return.</li>
              <li>Submitting the tax return within the legal deadline.</li>
            </ul>
          </div>
          <div className="art_2 pic_img">
            <img src="/serv8.jpg" alt="" />
          </div>
        </div>
        <div className="art scontainer">
          <div className="art_2 pic_img">
            <img src="/serv5.jpg" alt="" />
          </div>
          <div className="art_1">
            <h2>Tax advisory services</h2>
            <ul>
              <li>
                Carrying out tax advisory studies and tax restructuring in the
                light
                <br /> of applicable tax laws and regulations.
              </li>
              <li>Responding to customer inquiries in all types of taxes.</li>
              <li>
                Responding to all inquiries related to international agreements
                <br />
                and avoiding double taxation.
              </li>
              <li>
                Carry out tax impact studies, indicate the tax risks associated
                <br />
                with transactions, and determine the necessary tax allocations.
              </li>
            </ul>
          </div>
        </div>
        <div className="art scontainer">
          <div className="art_1">
            <h2>Tax examination services</h2>
            <ul>
              <li>
                Review all financial analyzes in addition to the documents
                <br />
                related to the examination before the beginning of the
                <br />
                examination.
              </li>
              <li>
                Conducting interviews with the tax officer, attending the tax
                <br />
                examination, and completing any data required by the examining
                <br />
                officer during the stages of the tax examination.
              </li>
              <li>Follow up the tax examination during all its stages.</li>
              <li>
                Objection to the linking forms on behalf of the company, with a
                <br />
                note on our part to the client of the need to send the forms to
                <br />
                us as soon as they are received so that we can submit the
                <br />
                objection within the specified legal period.
              </li>
              <li>
                Studying the examination memorandum and preparing a summary
                <br />
                explaining our opinion and suggestions, then discussing the
                <br />
                details with the client.
              </li>
              <li>
                Preparing a defense memorandum to object to points of
                <br /> disagreement with the competent tax office.
              </li>
              <li>
                Representing the company in all interviews before the internal
                <br />
                committee convenes to discuss our opinions.
              </li>
              <li>
                Studying the results of the internal committees and discussing
                <br />
                their details with the client.
              </li>
            </ul>
          </div>
          <div className="art_2 pic_img">
            <img src="/serv6.jpg" alt="" />
          </div>
        </div>
        <div className="art scontainer">
          <div className="art_2 pic_img">
            <img src="/serv7.jpg" alt="" />
          </div>
          <div className="art_1">
            <h2>The work of auditing the financial statements</h2>
            <ul>
              <li>
                Reviewing, preparing and presenting the financial statements in
                <br />
                accordance with the requirements of Egyptian accounting
                <br />
                standards.
              </li>
              <li>
                Adopting auditing procedures in accordance with the Egyptian
                <br />
                auditing standards.
              </li>
              <li>Approving the financial statements.</li>
              <li>
                Evaluation of internal control systems and internal control of
                <br />
                facilities.
              </li>
              <li>
                Setting accounting systems and preparing accounting courses.
              </li>
              <li>
                Accounting bookkeeping and bookkeeping services in accordance
                <br />
                with generally accepted accounting principles.
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <CounterUpPage /> */}
    </div>
  );
}

export default Details;
