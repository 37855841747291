import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div>
      {/* Start Header */}
      <div className="Header_s" id="Header_s">
        <div className="container_s">
          <Link to="/" className="logo">
            <img src="/WIC-01.png" alt="" />
          </Link>
          <ul className="main_nav">
            <li>
              <Link to="/information/service">Services</Link>
              {/* Start Megamenu  */}
              <div className="mega_menu">
                <div className="image">
                  <img src="/about33.png" alt="" />
                </div>
                <ul className="links">
                  <li>
                    <a href="#">
                      <i className="far fa-comments fa-fw"></i>Consulting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-user fa-fw"></i>Tax
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-building fa-fw"></i>Audit
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-check-circle fa-fw"></i>Managed
                      services
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>Assurance
                    </a>
                  </li>
                </ul>
                <ul className="links">
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>Law
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>Technology
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>corporate
                      finance
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>Advisory
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-clipboard fa-fw"></i>Private
                      Enterprise
                    </a>
                  </li>
                </ul>
              </div>

              {/* End Megamenu  */}
            </li>
            <li>
              <Link to="/information/insights">Insights</Link>
            </li>
            <li>
              <Link to="/information/join-us">Join Us</Link>
            </li>
            <li>
              <Link to="/information/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/information/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* End Header */}
    </div>
  );
}

export default Header;
