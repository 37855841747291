import emailjs from 'emailjs-com';

import React, { useEffect, useReducer } from 'react';
import CounterUpPage from '../CounterUpPage/CounterUpPage';
import './DetailsJoinScreen.css';
import { Helmet } from 'react-helmet';

const Join = () => {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_t0ybsel',
        'template_pbyww6c',
        e.target,
        'nEGdKZsqxNuGxsaod'
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }
  return (
    <div className="services">
      <Helmet>
        <title>Join - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seconed section */}
      <div className="our_join scontainer">
        <h1>Join Us</h1>
        <div className="cont_jo">
          <div className="img_join">
            <img src="/jo.png" alt="join" />
          </div>
          <div className="cont_join">
            <div className="inf_join">
              <lable>First Name</lable>
              <input type="text" placeholder="First Name" required />

              <lable>Last Name</lable>
              <input type="text" placeholder="Last Name" required />

              <lable>Email</lable>
              <input type="email" placeholder="Email" required />

              <lable>Phone</lable>
              <input type="text" placeholder="Phone" required />

              <lable>First Experience</lable>
              <input type="text" placeholder="First Experience" required />

              <lable>seconed Experience</lable>
              <input type="text" placeholder="seconed Experience" required />

              <lable>Your Cv</lable>
              <input
                type="file"
                name="upload"
                accept="application/pdf,application/vnd.ms-excel"
                style={{ border: 'none' }}
                required
              />
              <input
                className="sub"
                type="submit"
                value="Send"
                style={{
                  width: '100%',
                  border: '1px solid hsl(218, 100%, 50%)',
                  fontSize: '20px',
                  borderRadius: '3px',
                  margin: '20px 0 0 0 ',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
