import React, { useEffect, useReducer } from 'react';
import CounterUpPage from '../CounterUpPage/CounterUpPage';
import './DetailsInsightsScreen.css';
import { Helmet } from 'react-helmet';
import InsightBox from '../x/x';

function Insights() {
  return (
    <div className="services">
      <Helmet>
        <title>Insights - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs_service_in p_s">
                    <div className="all_seme_serv">
                      <div className="title_serv_in">
                        <h1>Insights</h1>
                      </div>
                      <div className="small_description_serv">
                        We're like the ultimate wingman for entrepreneurs.
                        You've got the great idea, we've got the know-how to
                        make it happen. From creating a plan to getting funding
                        and marketing, we've got your back every step of the
                        way. We're here to help you turn your dream into a
                        reality and build a badass business that everyone will
                        envy. So don't be afraid to shoot us a message and let's
                        get this party started!
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* seconed section */}
      <div className="our_service scontainer">
        <div className="service">
          {/* Start Introduction Insights */}
          <div className="intro_abouts_insig">
            <div className="intro_about_insig">
              <h1>Insights</h1>
            </div>
            <div className="elems_insig">
              <div className="elem_insig">
                <img src="/in1.webp" alt="" />
                <span></span>
              </div>
              <div className="elem_insig">
                <img src="/in2.webp" alt="" />
                <span></span>
              </div>
              <div className="elem_insig">
                <img src="/in3.webp" alt="" />
                <span></span>
              </div>
              <div className="elem_insig">
                <img src="/in4.webp" alt="" />
                <span></span>
              </div>
              <div className="elem_insig">
                <img src="/in5.jpeg" alt="" />
                <span></span>
              </div>
              <div className="elem_insig">
                <img src="/in6.jpeg" alt="" />
                <span></span>
              </div>
            </div>
          </div>
          {/* End Introduction Insights */}
          {/* Start Introduction About Importance Insights In Wise */}
          <div className="impor_insig">
            <h1>What the WISE Office will help you with insight ?</h1>
            <p>
              Our company is a leading provider of solutions that help
              entrepreneurs take their ideas from concept to reality. We
              specialize in providing tools, resources, and support that enable
              aspiring business owners to create, develop, and grow their
              ventures with confidence.
            </p>
            <p>
              Our suite of services includes everything from business planning
              and market research to funding and marketing strategies. We work
              closely with our clients to understand their unique needs and
              goals, and we tailor our solutions to meet those needs in the most
              effective and efficient way possible.
            </p>
            <p>
              Whether you're just starting out or looking to take your existing
              business to the next level, we're here to help. Our team of
              experienced professionals has a proven track record of success,
              and we're committed to helping our clients achieve their dreams
              and build thriving businesses that make a positive impact on the
              world.
            </p>
            <p>
              So if you're an entrepreneur with a great idea, we invite you to
              partner with us and see what we can do together. Let's create,
              develop, and grow your business together!
            </p>
          </div>
          {/* End Introduction About Importance Insights In Wise */}
        </div>
      </div>
      <div className="questions scontainer">
        <h2>Questions we will give you an answer</h2>
        <InsightBox />
      </div>
    </div>
  );
}

export default Insights;
