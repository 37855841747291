import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seconed section */}
      <div className="s_s1">
        <section className="section ">
          <div className="section-container">
            <h2>Feature</h2>
            <div className="cont-artical">
              <ul class="cards">
                <li>
                  <a href="" class="card">
                    <img
                      src="ToT_Podcast_Tile_Ep12-Climate_670x377.jpg"
                      class="card__image"
                      alt=""
                    />
                    <div class="card__overlay">
                      <div class="card__header">
                        <svg
                          class="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <div class="card__header-text">
                          <h3 class="card__title">work team</h3>
                        </div>
                      </div>
                      <p class="card__description">
                        The WISE Foundation consists of a team of experts who
                        represent the Foundation, and the Foundation represents
                        them, and the Foundation is one.
                      </p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="" class="card">
                    <img src="as.jpg" class="card__image" alt="" />
                    <div class="card__overlay">
                      <div class="card__header">
                        <svg
                          class="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <div class="card__header-text">
                          <h3 class="card__title">
                            Work in harmony and harmony
                          </h3>
                        </div>
                      </div>
                      <p class="card__description">
                        Wise is represented by many departments, which
                        distinguish each one from the other, and these
                        departments work together in one track and in a
                        continuous supply chain without interruption until we
                        achieve the best customer satisfaction.
                      </p>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="" class="card">
                    <img src="image-344.jpg" class="card__image" alt="" />
                    <div class="card__overlay">
                      <div class="card__header">
                        <svg
                          class="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        <div class="card__header-text">
                          <h3 class="card__title">
                            Organization and time management
                          </h3>
                        </div>
                      </div>
                      <p class="card__description">
                        One of the advantages of Wise is that it is able to
                        complete tasks in the shortest time and with high
                        efficiency, which distinguishes it from others and
                        increases its customers.
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* third section */}
        <section>
          <div className="explore scontainer">
            <div className="title-container">
              <span className="title-explore">
                Find out who the WISE Foundation is
              </span>
              <span className="subtitle">
                WISE has several strategies for finding solutions to problems
              </span>
            </div>
            <div className="btn-container">
              <div className="btn-More">Learn More</div>
            </div>
          </div>
        </section>

        <section>
          <div className="industries_container">
            <div className="industries scontainer">
              <h2>Industries</h2>
              <div className="cont_industries">
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon1.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon2.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon3.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon4.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon6.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
                <div className="inf_industries">
                  <div className="contImg">
                    <img src="/icon5.png" alt="ic" />
                  </div>
                  <h2>Asset Management</h2>
                  <p>
                    Working with asset management firms, from institutional
                    investors to wealth management, helping them make bold
                    decisions required for future success.
                  </p>
                </div>
              </div>
              <div className="button_industries">
                <span>All Industries</span>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="service scontainer">
            <div className="container_service">
              <div className="title_service">
                <h2>Service</h2>
              </div>
              <div className="cont_service">
                <div className="item_service">
                  <img
                    src="campaign-creators-yktK2qaiVHI-unsplash.jpg"
                    alt="service-imge"
                  />
                  <h2>Audit</h2>
                  <p>
                    We are committed to serving the public interest by providing
                    objective assurance over data on which investors rely.
                  </p>
                </div>
                <div className="item_service">
                  <img
                    src="charlesdeluvio-Lks7vei-eAg-unsplash.jpg"
                    alt="service-imge"
                  />
                  <h2>Tax & Legal</h2>
                  <p>
                    You face steep compliance demands and complex changes; we
                    help you transform your function to make a greater impact.
                  </p>
                </div>
                <div className="item_service">
                  <img
                    src="thisisengineering-raeng-h6gCRTCxM7o-unsplash.jpg"
                    alt="service-imge"
                  />
                  <h2>Advisory</h2>
                  <p>
                    Together, we help you create lasting value and responsible
                    growth to make your business fit for tomorrow.
                  </p>
                </div>
                <div className="item_service">
                  <img src="nasa-Q1p7bh3SHj8-unsplash.jpg" alt="service-imge" />
                  <h2>Private Enterprise</h2>
                  <p>
                    Helping entrepreneurs build great businesses. We know what
                    it takes to be successful at each stage of your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="button_service">
              <Link to={'/information/service'}>
                <span>All Service</span>
              </Link>
            </div>
          </div>
        </section>
        <section>
          <div className="content_join ccontainers scontainer">
            <div className="img_join">
              <img src="join.jpg" alt="join" />
            </div>
            <div className="contect_join">
              <h2>Join our teams</h2>
              <p>
                Our HR policy has been designed to serve our business ambition:
                we remain faithful to our values, we recruit the best talent,
                regardless of origin.
              </p>
              <span>Read More</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
