import React from 'react';
import './insight-box.css';

const insightDescriptions = [
  'What are Insights? Defining the concept',
  'Why are Insights important? The value of gaining understanding',
  'Different types of Insights: Behavioral, Market, Competitive, etc.',
  'Sources of Insights: Data, Customer Feedback, Market Research, etc.',
  'The role of Insights in decision-making',
  'How to gain Insights: Analyzing Data, Conducting Surveys, A/B Testing, etc.',
  'The importance of Insights in innovation',
  'The impact of Insights on customer experience',
  'The importance of insights in Marketing: Personalization, Segmentation, etc.',
  'How insights can improve product development',
  'The role of insights in pricing strategy',
  'Using insights to improve supply chain management',
  'Insights and workforce management: Improving employee engagement and retention',
  'The impact of insights on financial performance',
  'Using insights to optimize customer acquisition and retention',
  'The role of insights in social responsibility and sustainability',
  'The impact of insights on overall business performance.',
  'The future of Insights: Predictive Analytics and beyond.',
];

const InsightBox = () => {
  const leftInsights = insightDescriptions.slice(0, 9);
  const rightInsights = insightDescriptions.slice(9, 18);

  return (
    <div className="insight-box-container">
      <div className="insight-section">
        <div className="section-title">Understanding Insights:</div>
        <div className="insight-list">
          {leftInsights.map((insight, index) => (
            <div className="insight-item " key={index}>
              <p>{insight}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="insight-section">
        <div className="section-title">Applying Insights:</div>
        <div className="insight-list">
          {rightInsights.map((insight, index) => (
            <div className="insight-item " key={index}>
              <p>{insight}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InsightBox;
