import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer_container scontainer">
        <div className="inf_footer">
          <div className="ser_foot">
            <h3>Services</h3>
            <span>
              <Link href="#">Audit and assurance</Link>
            </span>
            <span>
              <Link href="#">Outsourcing and Accounting</Link>
            </span>
            <span>
              <Link href="#">Tax</Link>
            </span>
            <span>
              <Link href="#">Advisory & IT Services</Link>
            </span>
            <span>
              <Link href="#">Transaction services</Link>
            </span>
          </div>
          <div className="job_foot">
            <h3>Join Us</h3>
            <span>
              <Link href="#">Job Application</Link>
            </span>
          </div>
          <div
            className="about_foot"
            style={{
              lineHeight: '2.2',
            }}
          >
            <h3>Contact us</h3>
            <span>Email:</span> info@wise.com.eg
            <br />
            <span>Phone:</span> 01004655279
            <br />
            <span>Location:</span> 1 High Dam St, Dokki, Cairo, Egypt
            <div style={{ margin: '10px 0  ' }}>
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=100064046588864"
                  target={'_blank'}
                >
                  <img src="/facebook.png" style={{ width: '30px' }} alt="" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100064046588864"
                  target={'_blank'}
                >
                  Facebook
                </a>
              </div>

              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <a href="https://www.linkedin.com/in/wise-consultancy-0800b71a4/">
                  <img src="/linkedin.png" style={{ width: '30px' }} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/in/wise-consultancy-0800b71a4/"
                  target={'_blank'}
                >
                  Linkedin
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="end_footer">
          &copy; All rights reserved for Wise Consultations - 2022 - Egypt.
          Developed by{' '}
          <a
            href="https://www.linkedin.com/in/ahmed-elsayed-60b3551a5/"
            target={'_blank'}
          >
            Ahmed
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
