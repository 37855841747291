import React, { useEffect, useReducer } from 'react';
import CounterUpPage from '../CounterUpPage/CounterUpPage';
import './DetailsAboutScreen.css';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div className="services">
      <Helmet>
        <title>About - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seconed section */}
      <div className="our_service ">
        <div className="service ">
          <h1 className="service_title">
            AB<span>OUT</span> US
          </h1>
          <div>
            <div className="section_mission">
              <h1>Our mission</h1>
              <div className="our_mission scontainer">
                <div className="our_mission_content ">
                  <p>
                    {' '}
                    Experience, professionalism, problem solving,Our office is
                    able to provide the highest levels of distinguished
                    professional services to meet your requirements in the areas
                    of auditing, taxation, financial advisory, setting up
                    financial systems and regulations, management accounting,
                    establishing companies, mergers, bookkeeping, or any
                    services related to these main services, and we are fully
                    qualified to meet your requests as The work is undertaken
                    when assigned to us by a responsible partner with a full
                    team of managers, main and assistant specialized technicians
                  </p>
                </div>
                <div className="our_mission_img">
                  <img src="/accountant.png" alt="about_img" />
                </div>
              </div>
            </div>
            <div className="section_mission">
              <h1>What is Wise?</h1>
              <div className="our_mission scontainer">
                <div className="our_mission_img">
                  <img src="/about22.png" alt="about_img" />
                </div>
                <div className="our_mission_content ">
                  <p>
                    Our purpose is to build trust in society and solve important
                    problems. In an increasingly complex world, we help
                    intricate systems function, adapt and evolve so they can
                    benefit communities and society – whether they are capital
                    markets, tax systems or the economic systems within which
                    business and society exist. We help our clients to make
                    informed decisions and operate effectively within them.
                  </p>
                  <p>
                    By using Wise, We will help you accomplish your task in the
                    shortest possible time and with the best efficiency and
                    effectiveness. 
                  </p>
                </div>
              </div>
            </div>

            <div className="section_mission">
              <div className="our_mission scontainer">
                <div className="our_mission_content ">
                  <p>
                    Our values define who we are, what we stand for, and how we
                    behave. While we come from different backgrounds and
                    cultures, our values are what we have in common. They guide
                    how we work with our clients and each other, inform the type
                    of work we do, and hold us accountable to do our best. They
                    govern our actions and determine our success. Our values
                    help us work towards our Purpose of building trust in
                    society and solving important problems. The trust that our
                    clients, communities and our people place in WISE, and our
                    high standards of ethical behaviour, are fundamental to
                    everything we do. 
                  </p>
                  <p>
                    Our values underpin our Code of Conduct which is our frame
                    of reference for the decisions we make every day. It’s how
                    we do business..
                  </p>
                </div>
                <div className="our_mission_img">
                  <img src="/about33.png" alt="about_img" />
                </div>
              </div>
            </div>
            <div className="section_mission">
              <div className="our_mission scontainer">
                <div className="our_mission_img">
                  <img src="/about44.png" alt="about_img" />
                </div>
                <div className="our_mission_content ">
                  <p>
                    Wise office is characterized by the fact that the work team
                    has great experience in the field of auditing, accounting,
                    taxation and financial investments in Egypt and the
                    surrounding countries. And its types (commercial -
                    industrial - service) over the years. The office provides
                    all kinds of professional services, which will be detailed
                    later. 
                  </p>
                  <p>
                    The office also serves many clients in all fields and
                    activities from the industrial sector, the tourism sector,
                    the hotel, the commercial sector, the educational sector,
                    and So is the medical sector.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
