import React, { useEffect, useReducer } from 'react';
import CounterUpPage from '../CounterUpPage/CounterUpPage';
import './DetailsContactScreen.css';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div className="services">
      <Helmet>
        <title>Contact - Wise Consulting</title>
      </Helmet>
      {/* First section */}
      <section className="HeroSlider">
        <div className="HeroSlider-slider slick-initialized slick-slider">
          <div className="slick-list draggable">
            <div className="slick-track slick-opacity">
              <div className="HeroSlider-slide HeroSlider-slide--overlay slick-slide slick-current slick-active">
                <div className="HeroSlider-cover">
                  <span className="HeroSlider-image imgs"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* seconed section */}
      <div className="our_service scontainer">
        <div className="our_join scontainer">
          <h1>Contact Us</h1>
          <div className="cont_jo">
            <div className="img_join">
              <img src="/co.png" alt="join" />
            </div>
            <div className="cont_join">
              <div className="inf_join">
                <lable>Full Name</lable>
                <input type="text" placeholder="Full Name" />

                <lable>Email</lable>
                <input type="email" placeholder="Email" />

                <lable>Subject</lable>
                <textarea
                  rows="4"
                  cols="30"
                  placeholder="Message"
                  style={{ height: '151px' }}
                ></textarea>
                <input
                  className="sub"
                  type="submit"
                  value="Send"
                  style={{
                    width: '100%',
                    border: '1px solid hsl(218, 100%, 50%)',
                    fontSize: '20px',
                    borderRadius: '3px',
                    margin: '20px 0 0 0 ',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
